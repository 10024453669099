import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as containerStyles from "./our-team.module.css"
import Seo from "../components/Seo/Seo"

const OurTeamPage = ({ data: { wpPage } }) => {
  return (
    <>
      <Seo title="Our Team" />
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl text-secondary font-extrabold tracking-tight sm:text-4xl">
                {wpPage.ourTeam.headingText}
              </h2>
              <p className="text-xl text-gray-500">
                {wpPage.ourTeam.subheadingText}
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                {wpPage.ourTeam.teamMembers.map(person => (
                  <li key={person.name} className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <div className="aspect-w-16 aspect-h-13 sm:aspect-w-3 sm:aspect-h-4 pb-0">
                        <GatsbyImage
                          image={getImage(person.image.localFile)}
                          className="h-80 w-full object-cover shadow-lg rounded-lg"
                          alt={person.image.altText}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{person.name}</h3>
                            <p className="text-primaryDarker20">
                              {person.title}
                            </p>
                          </div>
                          <div
                            className={`text-md text-gray-500 ${containerStyles.teamDescriptionText}`}
                            dangerouslySetInnerHTML={{
                              __html: person.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurTeamPage

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 11 }) {
      uri
      title
      ourTeam {
        headingText
        subheadingText
        teamMembers {
          name
          title
          description
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
